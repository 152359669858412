<template>
    <card title="Bookings" body-class="overflow-y-visible flex-col" class="mt-8">

        <div class="w-1/4">
            <multi-select
                label="Default label"
                v-model="label"
                :allow-clear="false"
                class="min-w-67"
                :options="labels">
            </multi-select>
        </div>

        <div class="ml-auto">
            <button class="btn" @click="save()">
                Update
            </button>
        </div>
    </card>
</template>

<script>

import axios from "axios";
import MultiSelect from "../../../components/MultiSelect";

export default {
    components: {MultiSelect},
    metaInfo () {
        return { title: this.$t('settings') }
    },

    data: () => ({
        labels: ['PNG', 'PDF', 'ZPL'],
        label: null,
    }),

    created() {
        this.fetchMeta()
    },

    computed: {
        user() {
            return this.$store.getters['auth/user'];
        },
    },

    methods: {
        async fetchMeta() {
            const { data } = await axios.get(this.$apiUrl.settings.profileBooking)
            this.label = data.data.booking_default_label
        },

        save() {
            axios.post(this.$apiUrl.settings.profileBooking, {
                booking_default_label: this.label
            }).then(() => {
                this.$snotify.success("Saved");
            })
        }
    }
}
</script>
